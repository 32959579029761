import React, { useEffect, useState } from "react";
import { Button, Input, MaskInput, Select } from "../../components";
import { useForm } from "react-hook-form";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

const institutions = [
  {
    label: "Yargıtay",
    value: "yargitay"
  },
  {
    label: "Danıştay",
    value: "danistay"
  },
  {
    label: "Yerel Mahkemeler",
    value: "yerel-mahkemeler"
  }
];

const UNITS_DUMMY_DATA = [
  {
    label: "1. Hukuk Dairesi",
    value: "1-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "2. Hukuk Dairesi",
    value: "2-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "3. Hukuk Dairesi",
    value: "3-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "4. Hukuk Dairesi",
    value: "4-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "5. Hukuk Dairesi",
    value: "5-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "Aile Hukuku Dairesi",
    value: "aile-hukuku-dairesi",
    institution: "danistay"
  },
  {
    label: "Ceza Dairesi",
    value: "ceza-dairesi",
    institution: "danistay"
  },
  {
    label: "İdari Dava Dairesi",
    value: "idari-dava-dairesi",
    institution: "danistay"
  },
  {
    label: "Vergi Dairesi",
    value: "vergi-dairesi",
    institution: "danistay"
  },
  {
    label: "1. İdare Mahkemesi",
    value: "1-idare-mahkemesi",
    institution: "yerel-mahkemeler"
  },
  {
    label: "2. İdare Mahkemesi",
    value: "2-idare-mahkemesi",
    institution: "yerel-mahkemeler"
  },
  {
    label: "1. Ceza Mahkemesi",
    value: "1-ceza-mahkemesi",
    institution: "yerel-mahkemeler"
  },
  {
    label: "2. Ceza Mahkemesi",
    value: "2-ceza-mahkemesi",
    institution: "yerel-mahkemeler"
  }
];

const DUMMY_RESULT_DATA = [
  {
    mainNumber: "2019/12345",
    decisionNumber: "2019/12345",
    decisionDate: "01/01/2019",
    institution: "Yargıtay",
    unit: "1. Hukuk Dairesi",
    title: "Örnek Karar Başlığı",
    summary:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus ut nunc ultricies aliquam. Nulla facilisi. Nullam nec nunc nec nunc ultricies aliquam. Nulla facilisi. Nullam nec nunc nec nunc ultricies aliquam. Nulla facilisi. Nullam nec nunc nec nunc ultricies aliquam. Nulla facilisi. Nullam nec nunc nec nunc ultricies aliquam. Nulla facilisi. Nullam nec nunc nec nunc ultricies aliquam. Nulla facilisi. Nullam nec nunc nec nunc ultricies aliquam. Nulla facilisi. Nullam nec nunc nec nunc ultricies aliquam. Nulla facilisi. Nullam nec nunc nec nunc ultricies aliquam. Nulla facilisi. Nullam nec nunc nec nunc ultricies aliquam. Nulla facilisi."
  },
  {
    mainNumber: "2019/12345",
    decisionNumber: "2019/12345",
    decisionDate: "01/01/2019",
    institution: "Yargıtay",
    unit: "1. Hukuk Dairesi",
    title: "Örnek Karar Başlığı",
    summary:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus ut nunc ultricies aliquam. Nulla facilisi. Nullam nec nunc nec nunc ultricies aliquam. Nulla facilisi. Nullam nec nunc nec nunc ultricies aliquam. Nulla facilisi."
  }
];

const SearchJurisprudence = () => {
  const [showDetailSearch, setShowDetailSearch] = useState(true);
  const [units, setUnits] = useState(UNITS_DUMMY_DATA);
  const [results, setResults] = useState([]);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset
  } = useForm();

  useEffect(() => {
    if (watch("institution")) {
      setUnits(
        UNITS_DUMMY_DATA.filter(
          unit => unit.institution === watch("institution").value
        )
      );
    } else {
      setUnits(UNITS_DUMMY_DATA);
    }
  }, [watch("institution")]);

  const onReset = () => {
    reset({
      search: "",
      institution: "",
      units: [],
      mainNumber: "",
      decisionNumber: "",
      decisionDateStart: "",
      decisionDateEnd: ""
    });
  };

  const onSubmit = () => {
    setShowDetailSearch(false);
    setResults(DUMMY_RESULT_DATA);
  };

  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col justify-center">
      <h1
        className={
          "mb-4  pb-2 font-display text-xl font-medium text-gray-900 dark:border-gray-700 dark:text-white"
        }
      >
        İçtihat Arama
      </h1>
      <section className={"flex min-w-full flex-col "}>
        <div className={"flex gap-4"}>
          <Input
            name="search"
            error={errors.search}
            rules={{}}
            register={register}
            className="mb-4"
            placeholder="Aranacak kelimeyi girin"
          />
        </div>
        {showDetailSearch && (
          <>
            <div className={"flex gap-4"}>
              <Select
                options={institutions}
                name="institution"
                error={errors.institution}
                rules={{}}
                control={control}
                placeholder="Kurum"
              />
              <Select
                options={units}
                name="units"
                error={errors.units}
                rules={{}}
                isMulti={true}
                control={control}
                placeholder="Birim"
              />
            </div>
            <div className={"flex gap-4"}>
              <MaskInput
                mask="9999/99999"
                maskPlaceholder="1234/12345"
                name="mainNumber"
                // @ts-ignore
                error={errors.mainNumber}
                register={register}
                placeholder="Esas No"
              />
              <MaskInput
                mask="9999/99999"
                maskPlaceholder="1234/12345"
                name="decisionNumber"
                // @ts-ignore
                error={errors.decisionNumber}
                register={register}
                placeholder="Karar No"
              />
            </div>
            <div className={"flex gap-4"}>
              <MaskInput
                mask="99/99/9999"
                maskPlaceholder="GG/AA/YYYY"
                name="decisionDateStart"
                // @ts-ignore
                error={errors.decisionDateStart}
                register={register}
                placeholder="Karar Tarihi (Başlangıç)"
              />
              <MaskInput
                mask="99/99/9999"
                maskPlaceholder="GG/AA/YYYY"
                name="decisionDateEnd"
                // @ts-ignore
                error={errors.decisionDateEnd}
                register={register}
                placeholder="Karar Tarihi (Bitiş)"
              />
            </div>
          </>
        )}
        <div className={"flex items-center justify-end gap-4"}>
          <button
            className="flex items-center gap-1 text-sm font-medium text-primary-600 transition-colors duration-200 ease-in-out hover:text-primary-800"
            onClick={() => setShowDetailSearch(!showDetailSearch)}
          >
            Detaylı aramayı {showDetailSearch ? "kapat" : "göster"}
            {!showDetailSearch ? (
              <IconChevronDown size={20} />
            ) : (
              <IconChevronUp size={20} />
            )}
          </button>
          <div className={"flex justify-end gap-4"}>
            <Button
              onClick={() => onReset()}
              isFullWidth={false}
              variant="secondary"
            >
              Sıfırla
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isFullWidth={false}
            >
              Ara
            </Button>
          </div>
        </div>
      </section>
      <section className="mt-10 flex flex-col gap-4">
        {results.length > 0 && (
          <div className="text-sm text-gray-700">
            Sonuçlar: <strong>{results.length}</strong> sonuç gösteriliyor
          </div>
        )}
        {results.map((result, index) => (
          <div
            key={index}
            className={
              "flex cursor-pointer flex-col rounded bg-gray-50 p-4 transition duration-300 ease-in-out hover:shadow"
            }
          >
            <div className={"flex flex-col gap-2"}>
              <div className="font-medium text-gray-800">
                {result.institution} {result.unit} Kararı
              </div>
              <div className="flex gap-3 text-sm text-gray-700">
                <div className="flex gap-1">
                  <span>Esas No:</span>
                  <strong className="font-medium">{result.mainNumber}</strong>
                </div>
                <div className="flex gap-1">
                  <span>Karar No:</span>
                  <strong className="font-medium">
                    {result.decisionNumber}
                  </strong>
                </div>
                <div className="flex gap-1">
                  <span>Karar Tarihi:</span>
                  <strong className="font-medium">{result.decisionDate}</strong>
                </div>
              </div>
              <div className={"text-sm text-gray-600"}>{result.summary}</div>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default SearchJurisprudence;

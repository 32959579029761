import React from "react";
import { ChatInput } from "../index";

const ThreadChatInputComponent = ({ newThread, threadId, onSuccess }) => {
  return (
    <div className="fixed bottom-10 flex w-full max-w-4xl gap-10 lg:left-auto lg:right-auto lg:max-w-xl md:w-11/12">
      <div className={"flex-1"}>
        <ChatInput
          newThread={newThread}
          threadId={threadId}
          onSuccess={onSuccess}
        />
      </div>
      <div className="w-64 lg:hidden"></div>
    </div>
  );
};

export default ThreadChatInputComponent;

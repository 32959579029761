import React, { useEffect, useState } from "react";
import { Button, Input, MaskInput, Select } from "../../components";
import { useForm } from "react-hook-form";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

const types = [
  {
    label: "Kanunlar",
    value: "kanunlar"
  },
  {
    label: "Cumhurbaşkanı Kararları",
    value: "cb-kararlar"
  },
  {
    label: "Tüzükler",
    value: "tuzuk"
  },
  {
    label: "Cumhurbaşkanlığı Kararnameleri",
    value: "cb-kararnameler"
  },
  {
    label: "Cumhurbaşkanlığı Genelgeleri",
    value: "cb-genelgeler"
  },
  {
    label: "Kurum, Kuruluş ve Üniversite Yönetmelikleri",
    value: "Kurum, Kuruluş ve Üniversite Yönetmelikleri"
  },
  {
    label: "Cumhurbaşkanlığı / Bakanlar Kurulu Yönetmelikleri",
    value: "Cumhurbaşkanlığı / Bakanlar Kurulu Yönetmelikleri"
  },
  {
    label: "Tebliğler",
    value: "teblig"
  }
];

const UNITS_DUMMY_DATA = [
  {
    label: "1. Hukuk Dairesi",
    value: "1-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "2. Hukuk Dairesi",
    value: "2-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "3. Hukuk Dairesi",
    value: "3-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "4. Hukuk Dairesi",
    value: "4-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "5. Hukuk Dairesi",
    value: "5-hukuk-dairesi",
    institution: "yargitay"
  },
  {
    label: "Aile Hukuku Dairesi",
    value: "aile-hukuku-dairesi",
    institution: "danistay"
  },
  {
    label: "Ceza Dairesi",
    value: "ceza-dairesi",
    institution: "danistay"
  },
  {
    label: "İdari Dava Dairesi",
    value: "idari-dava-dairesi",
    institution: "danistay"
  },
  {
    label: "Vergi Dairesi",
    value: "vergi-dairesi",
    institution: "danistay"
  },
  {
    label: "1. İdare Mahkemesi",
    value: "1-idare-mahkemesi",
    institution: "yerel-mahkemeler"
  },
  {
    label: "2. İdare Mahkemesi",
    value: "2-idare-mahkemesi",
    institution: "yerel-mahkemeler"
  },
  {
    label: "1. Ceza Mahkemesi",
    value: "1-ceza-mahkemesi",
    institution: "yerel-mahkemeler"
  },
  {
    label: "2. Ceza Mahkemesi",
    value: "2-ceza-mahkemesi",
    institution: "yerel-mahkemeler"
  }
];

const DUMMY_RESULT_DATA = [
  {
    title: "DIŞİŞLERİ TEŞKİLATINI GÜÇLENDİRME VAKFI KANUNU",
    lawNumber: "1234",
    officialGazetteNumber: "1234",
    acceptanceDate: "01/01/2021",
    officialGazetteDate: "01/01/2021",
    composition: 5,
    type: "Kanunlar"
  },
  {
    title:
      "CEZA MUHAKEMESİ KANUNU İLE BAZI KANUNLARDA DEĞİŞİKLİK YAPILMASINA DAİR KANUN",
    lawNumber: "1234",
    officialGazetteNumber: "1234",
    acceptanceDate: "01/01/2021",
    officialGazetteDate: "01/01/2021",
    composition: 5,
    type: "Kanunlar"
  }
];

const SearchLegislation = () => {
  const [showDetailSearch, setShowDetailSearch] = useState(true);
  const [results, setResults] = useState([]);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset
  } = useForm();

  const onReset = () => {
    reset({
      search: "",
      type: "",
      lawNumber: "",
      officialGazetteNumber: "",
      acceptanceDateStart: "",
      acceptanceDateEnd: "",
      officialGazetteDateStart: "",
      officialGazetteDateEnd: ""
    });
  };

  const onSubmit = () => {
    setShowDetailSearch(false);
    setResults(DUMMY_RESULT_DATA);
  };

  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col justify-center">
      <h1
        className={
          "mb-4  pb-2 font-display text-xl font-medium text-gray-900 dark:border-gray-700 dark:text-white"
        }
      >
        Mevzuat Arama
      </h1>
      <section className={"flex min-w-full flex-col "}>
        <div className={"flex gap-4"}>
          <Input
            name="search"
            error={errors.search}
            rules={{}}
            register={register}
            className="mb-4"
            placeholder="Aranacak kelimeyi girin"
          />
          <Select
            options={types}
            name="type"
            error={errors.type}
            rules={{}}
            control={control}
            placeholder="Mevzuat Türü"
          />
        </div>
        {showDetailSearch && (
          <>
            <div className={"flex gap-4"}>
              <MaskInput
                mask="999999"
                maskPlaceholder="123456"
                name="lawNumber"
                // @ts-ignore
                error={errors.lawNumber}
                register={register}
                placeholder="Kanun Numarası"
              />
              <MaskInput
                mask="999999"
                maskPlaceholder="123456"
                name="officialGazetteNumber"
                // @ts-ignore
                error={errors.officialGazetteNumber}
                register={register}
                placeholder="Resmi Gazete Sayısı"
              />
            </div>
            <div className={"flex gap-4"}>
              <MaskInput
                mask="99/99/9999"
                maskPlaceholder="GG/AA/YYYY"
                name="acceptanceDateStart"
                // @ts-ignore
                error={errors.acceptanceDateStart}
                register={register}
                placeholder="Kabul Tarihi (Başlangıç)"
              />
              <MaskInput
                mask="99/99/9999"
                maskPlaceholder="GG/AA/YYYY"
                name="acceptanceDateEnd"
                // @ts-ignore
                error={errors.acceptanceDateEnd}
                register={register}
                placeholder="Kabul Tarihi (Bitiş)"
              />
            </div>
            <div className={"flex gap-4"}>
              <MaskInput
                mask="99/99/9999"
                maskPlaceholder="GG/AA/YYYY"
                name="officialGazetteDateStart"
                // @ts-ignore
                error={errors.officialGazetteDateStart}
                register={register}
                placeholder="Resmi Gazete Tarihi (Başlangıç)"
              />
              <MaskInput
                mask="99/99/9999"
                maskPlaceholder="GG/AA/YYYY"
                name="officialGazetteDateEnd"
                // @ts-ignore
                error={errors.officialGazetteDateEnd}
                register={register}
                placeholder="Resmi Gazete Tarihi (Bitiş)"
              />
            </div>
          </>
        )}
        <div className={"flex items-center justify-end gap-4"}>
          <button
            className="flex items-center gap-1 text-sm font-medium text-primary-600 transition-colors duration-200 ease-in-out hover:text-primary-800"
            onClick={() => setShowDetailSearch(!showDetailSearch)}
          >
            Detaylı aramayı {showDetailSearch ? "kapat" : "göster"}
            {!showDetailSearch ? (
              <IconChevronDown size={20} />
            ) : (
              <IconChevronUp size={20} />
            )}
          </button>
          <div className={"flex justify-end gap-4"}>
            <Button
              onClick={() => onReset()}
              isFullWidth={false}
              variant="secondary"
            >
              Sıfırla
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isFullWidth={false}
            >
              Ara
            </Button>
          </div>
        </div>
      </section>
      <section className="mt-10 flex flex-col gap-4">
        {results.length > 0 && (
          <div className="text-sm text-gray-700">
            Sonuçlar: <strong>{results.length}</strong> sonuç gösteriliyor
          </div>
        )}
        {results.map((result, index) => (
          <div
            key={index}
            className={
              "flex cursor-pointer items-center gap-4 rounded bg-gray-50 p-4 transition duration-300 ease-in-out hover:shadow"
            }
          >
            <div
              className={
                "flex h-full items-center border-r pr-4 font-medium text-gray-800"
              }
            >
              {result.lawNumber}
            </div>
            <div className={"flex flex-col gap-2"}>
              <div className="text-sm font-medium text-gray-800">
                {result.title}
              </div>
              <div className="flex gap-3 text-xs text-gray-700">
                <div className="flex gap-1">
                  <span>Tür:</span>
                  <strong className="font-medium">{result.type}</strong>
                </div>
                <div className="flex gap-1">
                  <span>Tertip:</span>
                  <strong className="font-medium">{result.composition}</strong>
                </div>
                <div className="flex gap-1">
                  <span>Resmi Gazete Tarihi:</span>
                  <strong className="font-medium">
                    {result.officialGazetteDate}
                  </strong>
                </div>
                <div className="flex gap-1">
                  <span>Resmi Gazete Sayısı:</span>
                  <strong className="font-medium">
                    {result.officialGazetteNumber}
                  </strong>
                </div>
                <div className="flex gap-1">
                  <span>Kabul Tarihi:</span>
                  <strong className="font-medium">
                    {result.acceptanceDate}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default SearchLegislation;

import {
  IconHistory,
  IconHome,
  IconMessageChatbot,
  IconScale,
  IconGavel
} from "@tabler/icons-react";

export default [
  {
    name: "Yeni konu",
    Icon: IconHome,
    path: "/new-thread",
    mainPath: "/new-thread",
    isVisible: false
  },
  {
    name: "Menü",
    isVisible: true,
    children: [
      {
        name: "Akıllı asistan",
        Icon: IconMessageChatbot,
        path: "/new-thread",
        mainPath: "/new-thread",
        isVisible: true
      },
      {
        name: "İçtihat arama",
        Icon: IconGavel,
        path: "/search-jurisprudence",
        mainPath: "/search-jurisprudence",
        isVisible: true
      },
      {
        name: "Mevzuat arama",
        Icon: IconScale,
        path: "/search-legislation",
        mainPath: "/search-legislation",
        isVisible: true
      },
      {
        name: "Geçmiş",
        Icon: IconHistory,
        path: "/history",
        mainPath: "/history",
        isVisible: true
      }
    ]
  }
];

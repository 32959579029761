import React from "react";
import { FormErrorMessage } from "components";
import InputMask from "react-input-mask";

const MaskInput = ({
  label,
  name,
  placeholder,
  register,
  error,
  mask,
  rules,
  ...props
}) => {
  return (
    <div className="mb-5 w-full">
      {label && (
        <label
          htmlFor={name}
          className={`mb-2 block w-full text-sm font-medium ${
            error ? "text-red-600" : "text-gray-900"
          }`}
        >
          {label}
        </label>
      )}
      <InputMask
        id={name}
        mask={mask}
        className={`block w-full  rounded-3xl border bg-white px-5 py-3 text-sm text-gray-700 focus:border-primary-500 focus:ring-1 focus:ring-primary-500  ${
          error
            ? "border-red-500 focus:border-red-500"
            : "border-gray-300 focus:border-gray-700"
        }`}
        placeholder={placeholder}
        {...register(name, rules)}
        {...props}
      />
      <FormErrorMessage label={label} error={error} />
    </div>
  );
};

export default MaskInput;
